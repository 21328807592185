export const baseColors = {
  black: '#000',
  white: '#FFF',
  skyblue: 'rgba(35, 122, 252, .1)',
  azure: '#06c',
  azureShade: '#0051a3',
  amber: '#FCA623',
  silver: '#EEE',
  grey: '#CCC',
  metal: '#AAA',
  bullet: '#666',
  caviar: '#222',
  ghost: '#111',

  blauw: '#00B4E6',
  geel: '#FFC21E',
  grijs: '#96b4b4',
  groen: '#85D138',
  paars: '#804ABD',
  rood: '#FF5400',
  roze: '#F157D4',
  wit: ' #FFFFFF',
  zwart: '#231f20',
  zwartTrans: 'rgb(0, 0, 0, 0.63)',
}
