module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#E8E8E8","display":"standalone","icon":"src/favicon.png","include_favicon":true,"name":"Wheelroom boilerplate","short_name":"boilerplate","start_url":"/","theme_color":"#00B4E6","cache_busting_mode":"query","legacy":true,"theme_color_in_head":true,"cacheDigest":"dae5990e671506c0bee63c79de2b3bad"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Work+Sans:400"]},"custom":{"families":["Typoni"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
